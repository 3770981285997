<script lang="ts" setup>
import type { IEvent } from "~/types";
import { EVENT_STATUS } from "~/constants";

export interface ScoreboardProps {
  event: IEvent;
}

const { t, locale } = useI18n();
const props = defineProps<ScoreboardProps>();
const formatDate = (date: Date | number, format: string) =>
  useDateFormat(new Date(date || ""), format, { locales: locale.value }).value;

const formatScore = (score: number) => score !== null && score !== undefined ? score : "—";
</script>

<template>
  <div
    class="grid lg:grid-cols-[1fr_300px_1fr] md:grid-cols-[1fr_230px_1fr] grid-cols-[1fr_165px_1fr] gap-4 px-4 scoreboard pt-12 py-4 lg:pb-4 md:px-3 lg:pt-4 xl:pt-5"
  >
    <NuxtLink :to="{ path: `/teams/${props.event.game_info.team1_id}` }">
      <div
        class="relative flex flex-col items-center gap-2 -mx-5 translate-x-1/2 xl:gap-4 xl:flex-row-reverse right-10 xl:right-0 md:right-14 xl:translate-x-0 xl:mx-0 rtl:left-10 rtl:xl:left-0 rtl:-translate-x-1/2 rtl:md:left-14 rtl:right-auto rtl:xl:translate-x-0"
      >
        <UAvatar
          :src="props.event.game_info.team1_logo_url"
          :alt="props.event.game_info.team1_name"
          :size="88"
        />
        <h3
          class="font-medium text-center xl:text-right rtl:xl:text-left min-[1500px]:text-2xl max-[1500px]:text-[1.25rem] max-[1500px]:leading-[1.5rem] max-[768px]:text-base dark:text-white"
        >
          {{ props.event.game_info.team1_name }}
        </h3>
        <UIcon
          v-if="isFeatureEnabled('enable_event_add_game_info') && !props.event.game_info.team1_is_official"
          v-tooltip="t('pages.events.scoreboard_team_unofficial_icon_tooltip')"
          name="warning"
          class="h-4 w-4 text-orange-500 hover:text-orange-600 inline-block shrink-0"
        />
      </div>
    </NuxtLink>
    <div
      class="flex flex-wrap self-start justify-between gap-2 md:justify-center md:gap-4 :pt-2 md:pt-4 center md:flex-nowrap"
    >
      <div class="home flex row-start-[left] row-end-[left] items-center gap-2 shrink-0 md:flex-1">
        <div
          v-if="event.status === EVENT_STATUS.VOD"
          class="text-2xl font-bold md:text-4xl lg:text-[2.5rem] xl:text-5xl ps-3 md:ps-0 dark:text-white"
        >
          {{ formatScore(props.event.game_info.team1_score) }}
        </div>
        <div
          v-if="props.event.game_info.team1_score > props.event.game_info.team2_score"
          class="border-solid border-y-transparent border-y-[6px] ltr:border-r-red-500 ltr:border-r-[9px] ltr:border-l-0 rtl:border-l-red-500 rtl:border-l-[9px] rtl:border-r-0"
        />
      </div>
      <div
        class="relative row-start-[top] row-end-[top] col-span-3 flex place-content-center text-center text-neutral-light-800 font-medium text-xs md:text-sm md:flex-col lg:w-40 w-[100%] md:w-auto flex-row order-first md:order-none dark:text-neutral-dark-200"
      >
        <p>{{ formatDate(props.event.start_date, "hh:mm A") }}</p>
        <p class="ml-2 capitalize md:ml-0">
          {{ formatDate(props.event.start_date, "MMMM DD, YYYY") }}
        </p>
      </div>
      <div
        class="relative row-start-[centre] row-end-[centre] flex items-center justify-center text-5xl md:hidden after:absolute after:top-[50%] after:left-[50%] after:w-[10px] after:h-[2px] after:bg-[#000000] dark:after:bg-white after:-translate-x-1/2 after:-translate-y-1/2 after:content-['']"
        :class="props.event.game_info.team1_score > props.event.game_info.team2_score ? '-ml-4' : '-mr-4'"
      />
      <div class="flex row-start-[right] row-end-[right] items-center justify-end gap-2 shrink-0 md:flex-1">
        <div
          v-if="props.event.game_info.team2_score > props.event.game_info.team1_score"
          class="border-solid border-y-transparent border-y-[6px] ltr:border-l-red-500 ltr:border-l-[9px] ltr:border-r-0 rtl:border-r-red-500 rtl:border-r-[9px] rtl:border-l-0"
        />
        <div
          v-if="event.status === EVENT_STATUS.VOD"
          class="text-2xl font-bold md:text-4xl lg:text-[2.5rem] xl:text-5xl pe-3 md:pe-0 dark:text-white"
        >
          {{ formatScore(props.event.game_info.team2_score) }}
        </div>
      </div>
    </div>
    <NuxtLink :to="{ path: `/teams/${props.event.game_info.team2_id}` }">
      <div
        class="relative flex flex-col items-center gap-2 -mx-5 -translate-x-1/2 xl:gap-4 xl:flex-row left-10 xl:left-0 md:left-14 xl:translate-x-0 xl:mx-0 rtl:right-10 rtl:xl:right-0 rtl:translate-x-1/2 rtl:md:right-14 rtl:left-auto rtl:xl:translate-x-0"
      >
        <UAvatar
          :src="props.event.game_info.team2_logo_url"
          :alt="props.event.game_info.team2_name"
          :size="88"
        />
        <h3
          class="font-medium text-center xl:text-left rtl:xl:text-right min-[1500px]:text-2xl max-[1500px]:text-[1.25rem] max-[1500px]:leading-[1.5rem] max-[768px]:text-base dark:text-white"
        >
          {{ props.event.game_info.team2_name }}
        </h3>
        <UIcon
          v-if="isFeatureEnabled('enable_event_add_game_info') && !props.event.game_info.team2_is_official"
          v-tooltip="t('pages.events.scoreboard_team_unofficial_icon_tooltip')"
          name="warning"
          class="h-4 w-4 text-orange-500 hover:text-orange-600 inline-block shrink-0"
        />
      </div>
    </NuxtLink>
  </div>
</template>
