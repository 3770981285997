<script lang="ts" setup>
const { t } = useI18n();
const emit = defineEmits(["close"]);

function onClose() {
  emit("close");
}
</script>

<template>
  <UModal
    appear
    :model-value="true"
  >
    <div class="p-4 pb-3 flex items-center justify-end">
      <UButton
        title="Close"
        variant="link-secondary"
        icon="close"
        class="h-5 w-5"
        @click="onClose"
      />
    </div>

    <div class="md:px-10 md:pb-10">
      <div class="flex flex-col">
        <div class="flex flex-col justify-center items-center pb-4">
            <UIcon
            name="send-for-breakdown"
            class="flex text-[64px] fill-[#233C5F] stroke-[#233C5F] dark:fill-[#FFFFFF] dark:stroke-[#FFFFFF]"
            />
        </div>
        <div class="flex text-2xl font-bold text-center pb-4">
            {{ t("pages.breakdowns.not_enough_balance_modal.title") }}
        </div>
        <div class="flex text-sm font-medium text-center pb-4">
            {{ t("pages.breakdowns.not_enough_balance_modal.description") }}
        </div>
        <div class="flex justify-center">
            <UButton
            size="lg"
            class="w-48"
            truncate
            :label="t('labels.okay')"
            @click="onClose"
            />
        </div>
      </div>
    </div>
  </UModal>
</template>
